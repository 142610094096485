<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <c-card title="ESF 사업계획 기본정보" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn v-show="editable && popupParam.bizPlanId" label="삭제" icon="delete_forever" @btnClicked="removeData" />
              <c-btn
                v-show="editable"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="data"
                :mappingType="mappingType"
                label="저장"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-plant
                required
                :editable="editable"
                type="edit"
                name="plantCd"
                v-model="data.plantCd">
              </c-plant>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-datepicker
                required
                type="year"
                :editable="editable"
                label="게시년도"
                name="bizPlanYear"
                v-model="data.bizPlanYear">
              </c-datepicker>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-text
                required
                :editable="editable"
                label="제목"
                name="bizPlanTitle"
                v-model="data.bizPlanTitle">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-datepicker
                required
                :editable="editable"
                label="게시일"
                name="bizPlanDate"
                v-model="data.bizPlanDate">
              </c-datepicker>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-textarea
                :editable="editable"
                type="editor"
                label="ESF 사업계획"
                name="bizPlanContents"
                maxHeight="180px"
                :editheight="15"
                v-model="data.bizPlanContents">
              </c-textarea>
            </div>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-upload 
                :attachInfo="attachInfo"
                :editable="editable">
              </c-upload>
            </div>
          </template>
        </c-card>
      </div>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'esf-business-plan-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        bizPlanId: '',
      }),
    },
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'SAI_ESF_BIZ_PLAN',
        taskKey: '',
      },
      saveUrl: '',
      mappingType: 'POST',
      regUserName: '',
      deleteUrl: '',
      editable: true,
      isSave: false,
      data: {
        bizPlanId: '',
        plantCd: '',
        bizPlanDate: '',
        bizPlanYear: '',
        bizPlanContents: '',
        bizPlanTitle: '',
        regUserId: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.ebp.business.get.url;
      this.saveUrl = transactionConfig.sai.ebp.business.insert.url;
      this.deleteUrl = transactionConfig.sai.ebp.business.delete.url;
      // code setting
      this.data.bizPlanDate = this.$comm.getToday();
      // list setting 
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.bizPlanId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.bizPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;

          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)

          this.$set(this.attachInfo, 'taskKey', this.popupParam.bizPlanId)
        },);
      }
    },
    saveData() {
      if (this.popupParam.bizPlanId) {
        this.saveUrl = transactionConfig.sai.ebp.business.insert.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sai.ebp.business.update.url;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  //확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.bizPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.popupParam.bizPlanId) {
        this.$set(this.attachInfo, 'taskKey', this.popupParam.bizPlanId)
        this.$set(this.attachInfo, 'isSubmit', uid())
      }
      this.popupParam.bizPlanId = result.data
      this.getDetail();
    },
  }
};
</script>
